/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import "./layout.css"

import { Facebook, Instagram, Twitter } from "react-feather"
import { Link, graphql, useStaticQuery, withPrefix } from "gatsby"

import Header from "./header"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer className="footer pa5-ns pv5 ph4">
    <div className="container">
      <div className="footer-columns flex flex-row-ns flex-column">
        <div className="footer-column">
          <Link to="/" className="logo db" />
        </div>
      </div>
      <div className="flex flex-row-ns flex-column justify-between">
        <div className="footer-menu flex flex-row-ns flex-column pt4-ns pb0-ns pt4 pb4">
          <div className="footer-menu-item mr4-ns mv2">
            <Link className="no-underline footer-menu-item-link" to="/faq">
              FAQ
            </Link>
          </div>
          <div className="footer-menu-item mr4-ns mv2">
            <a
              className="no-underline footer-menu-item-link"
              href="https://app.termly.io/document/privacy-policy/86d0e2be-ac07-41fd-91ee-f7418d838747"
            >
              Privacy Policy
            </a>
          </div>
          <div className="footer-menu-item mr4-ns mv2">
            <Link
              className="no-underline footer-menu-item-link text-pink fw7"
              to="/signup"
            >
              Sign Up
            </Link>
          </div>
        </div>
        <div className="flex flex-row items-end-ns items-start">
          <Link to="http://instagram.com/vanity.app">
            <Instagram size={24} />
          </Link>
          <Link
            className="ml4-ns ml3"
            to="https://www.facebook.com/VanityApp/?view_public_for=237554817198640"
          >
            <Facebook size={24} />
          </Link>
          <Link className="ml4-ns ml3" to="https://twitter.com/GetVanity">
            <Twitter size={24} />
          </Link>
        </div>
      </div>
    </div>
  </footer>
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Manrope:wght@500;700&display=swap');
      </style>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/tachyons/4.11.1/tachyons.min.css"
        integrity="sha512-d0v474klOFSF7qD9WDvyRxAvXaWSxCHDZdnBSZQjo8BpVr6vpjwAgqetpqkKP38DzlOzdVPaLVnzzW1Ba8wB9w=="
        crossOrigin="anonymous"
      />
      <Helmet>
        <script src={withPrefix("scripts/jquery.js")} type="text/javascript" />
        <script src={withPrefix("scripts/menu.js")} type="text/javascript" />
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Website",
          "url": "https://www.getvanity.co",
          "name": "Vanity - Beauty Organization"
        }
      `}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
