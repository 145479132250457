import "./layout.css"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header className="header-container">
    <nav className="navbar">
      <div className="container flex flex-row justify-between pv4-ns ph0-ns pa4">
        <Link className="logo" to="/" />
        <div className="menu flex-ns flex-row items-center dn">
          <div className="menu-items pv2 mr5">
            <Link className="logo" to="/" />
          </div>
          <div className="menu-items pv2 mr5">
            <Link to="/faq">FAQ</Link>
          </div>
          <div className="menu-items pv2 mr5">
            <a href="https://app.termly.io/document/privacy-policy/86d0e2be-ac07-41fd-91ee-f7418d838747">
              Privacy Policy
            </a>
          </div>
          <div className="menu-items pv2">
            <Link className="sign-up-link" to="/signup">
              Sign Up
            </Link>
          </div>
        </div>
        <div className="menu-burger dn-ns flex"></div>
      </div>
    </nav>
    <div className="responsive-menu container-pink pa4 pt2 dn">
      <div className="menu-items pv3 mr5">
        <Link to="/faq">FAQ</Link>
      </div>
      <div className="menu-items pv3">
        <a href="https://app.termly.io/document/privacy-policy/86d0e2be-ac07-41fd-91ee-f7418d838747">
          Privacy Policy
        </a>
      </div>
      <div className="menu-items pv2">
        <Link to="/signup">Sign Up</Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
